'use client';

import { useRollbar } from '@rollbar/react';
import { useEffect } from 'react';

import { DefaultErrorPage } from '@/components/compat/DefaultErrorPage';

// This error page is the catch-all for all errors that are created
// for any page (after we have rendered the root layout and template).
// If the page fails to define its own error then this will catch it.
export default function ErrorPage({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  const rollbar = useRollbar();
  useEffect(() => {
    rollbar.error(error);
  }, [error, rollbar]);

  return <DefaultErrorPage reset={reset} />;
}
